const server = process.env.APP_SERVER || "103brest.e-med.by";

const config = {
	server: `https://${server}`,
	api: `https://${server}/api`,
	ws: `wss://${server}/ws/`,
	ws_local: `ws://localhost:7777`,
	menu: '3b0a2675-a11d-0474-4abc-c3848b998d5c'
};

export { config };
